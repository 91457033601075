import * as React from 'react';
import * as ReactDOM from 'react-dom';
import BookmarkButton from './components/bookmark-button/BookmarkButton';

function hydrateBookmark() {
    if (window.__PRELOADED_BOOKMARK_STATE__) {
        window.__PRELOADED_BOOKMARK_STATE__.forEach((value) => {
            const hydratedProps = value;
            const anchorElement = document.getElementById(hydratedProps.containerId);
            if (anchorElement)
                ReactDOM.hydrate(
                    <>
                        <BookmarkButton {...hydratedProps} />
                        <script suppressHydrationWarning={true} />
                    </>,
                    anchorElement
                );
        });
        delete window.__PRELOADED_BOOKMARK_STATE__;
    }
}
hydrateBookmark();

